import React, { useContext, useEffect, useState } from "react";
import { HotelContext } from "../../Context/HotelContext";
import "./grosstotalCard.css";
import { useSelector } from "react-redux";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import CloseIcon from "@mui/icons-material/Close";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { LoginContext } from "../../Context/UserLoginContext";
import axios from "axios";
import { BASE_URL } from "../../api/baseURL";
import { insertBookingInfoObj } from "../PayHotelPartial/insertBookingInfoObj";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const GrossTotalCardForBooking = ({
  appliedCoupons,
  selectedCoupon,
  handleAppliedCoupons,
  toggle,
}) => {
  const {
    selectedRoom,
    payHotel,
    toggleText,
    setToggleText,
    selectedRoomInfo,
    priceData,
    payPartialGross,
    setBookingInfo,
  } = useContext(HotelContext);
  const { userLoginData } = useContext(LoginContext);
  const [fullname, setFullname] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [mobile, setMobile] = useState(userLoginData?.phone_number || "");
  const singleHotelData = useSelector(
    (store) => store?.reducerHotel?.hotel?.hotel_data || {}
  );
  const enquiredData = useSelector(
    (store) => store?.urlReducer?.parmas_for_api || {}
  );
  const {
    checkintime,
    checkouttime,
    partial_tax_gst,
    partialamountpercentage,
    tax_info,
  } = useSelector((store) => store?.reducerHotel?.hotel?.hotel_data || {});
  const { to_date, from_date, no_of_adults, no_of_children } = useSelector(
    (store) => store?.urlReducer?.parmas_for_api || {}
  );
  const [formattedDateRange, setFormattedDateRange] = useState("");
  const [removeBookbtn, setRemoveBookBtn] = useState(true);
  const [loadingBooking, setLoadingBooking] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    const url = location.pathname;
    if (url === "/booking") {
      setRemoveBookBtn(true);
    }
    if (url === "/") {
      setRemoveBookBtn(false);
      setToggleText(true);
    }
  }, []);

  useEffect(() => {
    const formattedStartDate = formatDate(from_date);
    const formattedEndDate = formatDate(to_date);
    const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;
    setFormattedDateRange(formattedDateRange);
    localStorage.setItem("priceData", JSON.stringify(priceData));
  }, [from_date, to_date, selectedRoom, priceData]);

  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);
    const day = getDayName(dateObj.getDay());
    const date = dateObj.getDate();
    const month = getMonthName(dateObj.getMonth());
    return `${day} ${date} ${month}`;
  };

  const getDayName = (dayIndex) => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return days[dayIndex];
  };
  const [paymentId, setPaymentId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [signature, setSignature] = useState(null);
  const handlePayment = async () => {
    try {
      // Create an order on the backend
      const params = insertBookingInfoObj(
        selectedRoomInfo,
        userLoginData,
        singleHotelData,
        enquiredData,
        priceData
      );
      const orderResponse = await axios.post(
        BASE_URL + "/razorpay_create_order",
        {
          amount: priceData?.total * 100,
          booking_data: params,
        }
      );

      const { id: orderId } = orderResponse.data;

      const options = {
        key: "rzp_live_KQq8AX6XhkZAf2",
        amount: priceData?.total * 100, // Convert to paise
        currency: "INR",
        name: "RateBotAi",
        order_id: orderId,
        handler: async function (response) {
          const { razorpay_payment_id, razorpay_order_id, razorpay_signature } =
            response;
          setPaymentId(razorpay_payment_id);
          setOrderId(razorpay_order_id);
          setSignature(razorpay_signature);

          // Verify payment signature
          const verificationResponse = await axios.post(
            BASE_URL + "/razorpay_verify_payment",
            {
              payment_id: razorpay_payment_id,
              order_id: razorpay_order_id,
              signature: razorpay_signature,
              booking_data: params,
            }
          );
          if (verificationResponse.data.status == "Payment successful!") {
            setBookingInfo(verificationResponse.data.booking_data);
            navigate("/voucher");
          }
        },
        prefill: {
          name: fullname,
          email: userEmail,
          contact: mobile,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Payment failed:", error);
    }
  };

  const getMonthName = (monthIndex) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return months[monthIndex];
  };

  return (
    <div className="total-container">
      <div className="text">
        <h3 style={{ color: "#258039" }}>Your Stay</h3>
      </div>
      <div className="content-stay">
        <div className="checkin-checkout-cont">
          <div className="checkin total-card-text-style">
            <p className="text-color-check">Check-in</p>
            <p> {checkintime} AM</p>
          </div>
          <div className="checkin total-card-text-style">
            <p className="text-color-check">Check-out</p>
            <p> {checkouttime} AM</p>
          </div>
        </div>
        <div className="to-from-container total-card-text-style">
          <p>{formattedDateRange}</p>
          <p>
            {no_of_adults} Adults, {no_of_children} Child
          </p>
        </div>
      </div>
      <div className="gross-total-bill-details">
        <div className="gross-room-icon">
          <MeetingRoomIcon
            style={{ fontSize: "20px", marginRight: "10px", color: "#01509d" }}
          />
          <p style={{ fontSize: "14px", fontWeight: "600", color: "#01509d" }}>
            {" "}
            {selectedRoom?.roomtype}
          </p>
        </div>
        <div className="gross-room-guest-count-cont text-color-check">
          <div>
            {selectedRoom?.occupancy_room_count}Rooms
            <CloseIcon style={{ fontSize: "16px" }} />
            {selectedRoom?.room_price_date_wise?.reduce(
              (acc, curr) => curr?.no_days + acc,
              0
            )}
            Nights
            <CloseIcon style={{ fontSize: "16px" }} />
            {no_of_adults + no_of_children} Guests
          </div>
          <div>₹{priceData?.roomPrice}</div>
        </div>
        {priceData?.couponAmount > 0 ? (
          <div
            className="gross-total-tax text-color-check"
            style={{ margin: "0 10px" }}
          >
            <p>{priceData?.coupon_code}</p>

            <p
              style={{ color: "rgb(37, 128, 57)" }}
            >{`₹${priceData?.couponAmount} `}</p>
          </div>
        ) : null}

        <div className="gross-total-tax text-color-check">
          <p>Taxes & Service Fees</p>
          <p>{`₹${priceData?.tax} `}</p>
        </div>
      </div>
      <div className="gross-total-card-price-cont text-color-check">
        <h3>Total</h3>
        <h3 style={{ color: "rgb(37, 128, 57)" }}>{`₹${priceData?.total}`}</h3>
      </div>

      {toggle && (
        <div className="gross-total-btn">
          <button onClick={handlePayment}>Book Now</button>
        </div>
      )}
    </div>
  );
};

export default GrossTotalCardForBooking;
