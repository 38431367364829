import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useContext,
} from "react";
import Loader from "../Loader/Loader";
import Navbar from "../Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { getHotel } from "../../Redux/Hotel/action";
import "./layout.css";
import { setUserData } from "../../Redux/Urldata/action";
import Calendar from "../CheckInCheckOutCalendar/Calendar";
import GuestCount from "../GuestCount/GuestCount";
import GrossTotalCard from "../GrossTotalCard/GrossTotalCard";
import { getHotelById } from "../../api/getHotel.api";
import { HotelContext } from "../../Context/HotelContext";
import FilterRooms from "../FilterRooms/FilterRooms";
import RoomsList from "../RoomsList/RoomsList";
import AllRoutes from "../../Router/AllRoutes";
import Footer from "../Footer/Footer";

// Create a memoized selector using createSelector
const selectHotelData = createSelector(
  (store) => store?.reducerHotel.isLoading,
  (store) => store?.reducerHotel.hotel,
  (isLoading, hotel) => ({
    isLoading,
    hotel,
  })
);

const Layout = () => {
  const { isLoading, hotel } = useSelector(selectHotelData);
  const dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(window.location.href);
    const encodedData = url.searchParams.get("data");
    try {
      const parsedData = JSON.parse(encodedData);
      dispatch(
        setUserData({
          from_date: parsedData.from_date,
          to_date: parsedData.to_date,
          no_of_adults: parsedData.no_of_adults,
          room_count: parsedData.room_count,
          no_of_children: parsedData.no_of_children,
          selected_hotel: parsedData.selected_hotel,
          parmas_for_api: parsedData.parmas_for_api,
        })
      );
      dispatch(getHotel({ ...parsedData.parmas_for_api }));
    } catch (error) {
      console.error("Error parsing data:", error);
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  } else {
    return (
      <div className="layout-div-cont">
        <Navbar />
        <div className="routes-container-width">
          <AllRoutes />
        </div>
        <div className="footer-container">
          <Footer />
        </div>
      </div>
    );
  }
};

export default Layout;
